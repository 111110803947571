.app-login{
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      min-width: 100vw;
      background-color: #80010a;
      justify-content: center; /* Add this line */
      align-items: center; /* Add this line */
    }
  
.app-login #login-form-container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  
}

.app-login .login-header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #80010a;
  }

.app-login #login-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #80010a;
}

.app-login #login-form .input-field{
    min-width: 400px;
    display: flex;
    flex-wrap: wrap;
    color: white;
    font-size: 20px;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    
}

.app-login #login-form .input-field label{
  align-self: flex-start;
  /* padding-right: 8px; */
}
.app-login #login-form .input-field input{
  align-self: flex-end;
  border: none;
  outline: none;
  border-bottom: 2px solid white;
  background-color: transparent;
}

.app-login #login-form .login-button-container{
  min-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: 18px;

}

.app-login #login-form .login-button-container #login-button{
  /* width: 24px; */
  padding: 8px 16px;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid white;
}

.app-login #login-form .login-button-container #login-button:hover{
  background-color: white;
  border: 2px solid #80010a;
  color: #80010a;
}

.app-login #login-form .login-button-container #register{
  /* width: 24px; */
  padding: 8px 16px;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
}

.app-login #login-form .login-button-container #register span{
  text-decoration: underline;
}