
/* ________HeadSection___________________ */
.head-section{
    width: 100%;
    padding: 32px 16px 32px 16px;
    display: flex;
    gap: 16px;
    justify-content: space-around;
    min-height: 33vh;
    border-bottom: 1px solid var(--color-main);
}

.head-section > div{
    display: flex;
    /* background-color: var(--color-light); */
    width: 100%;
    justify-content: left;
    align-items: left;
    border-radius: 16px;
    color: var(--color-text-root);
}

.head-section .sub-list-container{
    padding-left: 20px;
}

.head-section .info{
    display: flex;
    flex-flow: column nowrap;
    padding: 32px;
    background-color: transparent;
    color: var(--color-light);
}
/* ________LogicSection__________________ */
.logic-section {
    position: relative;
    max-width: 100vw;
    
    display: flex;
    flex-direction: column;
    padding: 32px 16px 32px 16px;
    padding-bottom: 32px;
    border-bottom: 1px #ffffff solid;
    background-color: var(--color-text-root);
    color: var(--color-white);
}

.logic-section *::-webkit-scrollbar {
    width: 10px;
}

.logic-section *::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.logic-section *::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.logic-section *::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.logic-section .input-container {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    width: 100%;
    min-height: 33vh;
    justify-content: space-around;
    
}

.logic-section .input-container #input1,
.logic-section .input-container #input2 {
    flex: 2;
    /* background-color: aqua; */
    border-radius: 16px;
    padding: 8px;
    color: var(--color-text-dark);
}

.logic-section .input-container #budget{
    flex: 1;
    display: flex;
    flex-flow:column nowrap ;
    gap: 16px;
    justify-content: space-around;
    /* background-color: var(--color-bg); */
    
}

.logic-section .input-container #budget #turn-remain,
.logic-section .input-container #budget #time-remain { 
    flex: 2;
    padding: auto;
    display: flex;
    font-size: x-large;
    
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--color-main);
    border: 2px var(--color-white) solid;
    border-radius: 16px;
    

}
.logic-section .input-container #budget #run-button{
    flex: 1;
    display: flex;
    color: var(--color-text-root);
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    background-color: var(--color-white);
    cursor: pointer;
    border: 2px var(--color-white) solid;
}
.logic-section .input-container #budget #run-button:hover{
    background-color: var(--color-text-root);
    color: var(--color-white);
}

.logic-section .output {
    margin: 24px;
    padding: 8px;
    width: 100%;
    min-height: 20vh;
    max-height: 30vh;
    align-self: center;
    display: flex;
    justify-content: flex-start;
    color: var(--color-text-dark);
    background-color: #ffffff;
    border-radius: 16px;
    overflow: auto;
}

.logic-section .button-container{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
}

.logic-section .button-container > div{
    padding: 8px;
    border-radius: 8px;
    background-color: var(--color-dark);
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border: 2px var(--color-white) solid;
}

.logic-section .button-container > div:hover{
    padding: 8px;
    color: var(--color-text-root);
    background-color: var(--color-white);
}

.logic-section .button-container #buy-more{
    flex:2;
}

.logic-section .button-container #copy,
.logic-section .button-container #download{
    flex: 1;
}
.logic-section .invalid-code-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
}

.logic-section .invalid-code-buttons button {
    padding: 8px;
    flex: 1;
    border-radius: 8px;
    background-color: var(--color-white);
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border: 2px var(--color-white) solid;
    color: var(--color-text-root);
    /* color: black; */
    
}

.logic-section .invalid-code-buttons button:hover {
    padding: 8px;
    color: var(--color-white);
    background-color: var(--color-dark);
}
/*___Package style______________________________*/

.package-section{
    padding: 32px 16px 32px 16px;
    display: flex;
    flex-flow: column nowrap;
}

.package-section .package-container{
    display: flex;
    gap: 16px;
    justify-content: space-between;

}

.package-section .package-container .package{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: auto;
    min-height: 200px;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: xx-large;
    font-weight: 700;
    background-color: var(--color-text-root);
    color: var(--color-white);
    border-radius: 16px;
    cursor: pointer;
}
.package-section .package-container .package .package-info{
    margin: 10px;
    font-weight: 300;
    font-size: x-large;
}
.package-section .package-container .package.active{
    color: var(--color-text-root);
    background-color: var(--color-white);
    border: 2px var(--color-text-root) solid;
}

.package-section .package-container .package:hover{
    background-color: var(--color-dark);
    color: var(--color-light);
    border: 2px var(--color-text-root) solid;

}

.package-section .button-container{
    padding: 24px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.package-section .button-container .button{
    padding: 8px;
    width: 50%;
    background-color: var(--color-text-root);
    color: var(--color-white);
    border-radius: 8px;
    cursor: pointer;
}

.package-section .button-container .button:hover{
    background-color: var(--color-light);
    color: var(--color-dark);
    border: 2px var(--color-text-root) solid;
}

.discord-icon{
    /* position: -webkit-sticky; */
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}
.discord-icon img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}