.container {
    width: 100%;
    min-height: 100vh;
    color: var(--color-text-root);
    background-color: white;
    display: flex;
    align-items: center;
    overflow: auto;
  }


.container .app #policy-page{
    position: relative;
    width: 80%;
    padding: 20px;
    background-color: white;
    background-color: var(--color-bg-root);
    margin: 0 auto; /* Add this line */
}
.container .app #policy-page .disclaimer-policy{
    margin-bottom: 20px;
}

.container .app #policy-page .disclaimer-policy ol{
    list-style-type: decimal;
    margin: 20px 0px 20px 20px;
    
}
.container .app #policy-page label input{
    margin-right: 10px;
}
.container .app #policy-page .continue-btn.active{
    padding: 10px;
    border-radius: 10px;
    outline: none;
    border: 2px solid var(--color-text-root);
}

.container .app #policy-page .continue-btn.active:hover{
    background-color: var(--color-text-root);
    color: white;
}

.container .app #policy-page .continue-btn.disabled{
    padding: 10px;
    border-radius: 10px;
    outline: none;
    border: 2px solid var(--color-text-root);
    background-color: gray;
}
