/* Existing imports and variables */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;600;700&display=swap');

:root {
  --color-text-root: #80010a;
  --color-white: #f5f6f7;
  --color-bg: #1f2124;
  --color-text-dark: #383a3f;
}

.variable {
  --color-text: #80010a;
  --color-text-dark: #383a3f;
  --color-light: #f5f6f7;
  --color-gray: #999;
  --color-bg: #1f2124;
  --color-border: #252629;
}

/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
  color: inherit;
  font-size: inherit;
  scroll-behavior: smooth;
}

body {
  line-height: 1.8em;
  font-weight: 400;
  font-size: 16px;
}

a {
  text-decoration: none;
}

/* App styles */
.container {
  width: 100%;
  min-height: 100vh;
  color: var(--color-text-root);
  background-color: var(--color-bg);
  display: flex;
  align-items: center;
  overflow: auto;
}

.app {
  min-height: 50vh;
  margin: 0 auto;
  background-color: var(--color-white);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.05);
  position: relative;
}

.app-header {
  position: relative;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--color-white);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.left-section {
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 20px;
}

.nav-menu {
  display: flex;
  align-items: center;
}
.nav-menu>*{
  margin-right: 20px;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
  padding: 0 10px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-menu a {
  padding: 12px 16px;
  text-decoration: none;
  color: #333;
}

.dropdown-menu a:hover {
  background-color: #f1f1f1;
}

/* Styles cho sidebar */
.sidebar-dropdown {
  display: flex;
  flex-direction: column;
}

.sidebar-dropdown-menu {
  display: flex;
  flex-direction: column;
}

.sidebar-dropdown-menu a {
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.sidebar-dropdown-menu a:hover {
  background-color: #f1f1f1;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info p {
  margin-left: 10px;
}

#logout {
  cursor: pointer;
}

#logout:hover {
  color: rgb(248, 31, 11);
}

/* Sidebar nav menu for mobile */
.sidebar-nav-menu {
  display: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-menu {
    display: none;
  }

  .sidebar-nav-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: var(--color-white);
    padding-top: 60px;
    transition: left 0.3s ease;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .sidebar-nav-menu.open {
    left: 0;
  }

  .sidebar-nav-menu>*{
    margin: 15px 20px;
    color: var(--color-text-root);
    font-weight: 600;
  }

  .user-info p {
    font-size: 14px;
    margin-left: 10px;
  }
}

.sidebar-nav-menu {
  display: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  /* ... các style đã có ... */

  .sidebar-nav-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: var(--color-white);
    padding-top: 20px;
    transition: left 0.3s ease;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .sidebar-nav-menu.open {
    left: 0;
  }

  .sidebar-nav-menu .close-icon {
    align-self: flex-end;
    margin-right: 20px;
    font-size: 24px;
    cursor: pointer;
  }

  .sidebar-nav-menu a {
    margin: 15px 20px;
    color: var(--color-text-root);
    font-weight: 600;
  }

  /* Đảm bảo các liên kết không bị ảnh hưởng */
  .sidebar-nav-menu a:hover {
    color: rgb(248, 31, 11);
  }
}